import React from "react";

import { Divider, Grid, GridItem } from "@chakra-ui/react";

import HeaderText from "common/components/Header";

interface IProps {
	iteAnteriorExists: boolean
}
export const TableHeader: React.FC<IProps> = (props) => {
	const { iteAnteriorExists } = props;
	return (
		<React.Fragment>
			<Grid templateColumns={`repeat(${iteAnteriorExists ? 9 : 8}, 1fr)`} gap={1} w="95%">
				<GridItem colStart={6}>
					<HeaderText label="Ítems" />
				</GridItem>
				<GridItem colStart={7}>
					<HeaderText label="Índice" />
				</GridItem>
				{iteAnteriorExists ?
					<GridItem colStart={8} colSpan={2}>
						<HeaderText label="Índice ITE Anterior" />
					</GridItem> : undefined}
			</Grid>
			<Divider mt=".5rem" />
		</React.Fragment>
	);
};
