import React from "react";

import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";

import { capitalizeFirstLetter } from "helpers/text";

interface IProps {
	indice: string;
	hideColors?: boolean;
}

const IndiceIndicator: React.FC<IProps> = (props) => {
	const { indice, hideColors } = props;

	const indiceNumber = indice.toString().slice(-1);
	const indiceString = indice.toString().slice(0, -1);
	const subIndiceIndicator = indiceString.slice(-1);
	const subIndice =
		subIndiceIndicator === "E" ? subIndiceIndicator.toUpperCase() : capitalizeFirstLetter(indiceString.slice(-2));
	const firstLetters = indiceString.slice(0, subIndice === "E" ? -1 : -2).toUpperCase();

	const maxIndiceLength = (indice: string) => {
		if (indice.startsWith("NA") || indice.startsWith("NCN")) {
			return 3;
		} else {
			return 4;
		}
	};

	const statusColorSelector = (indiceNumber: string, maxValue: number) => {
		const status = parseInt(indiceNumber);
		const color =
			maxValue === 4
				? status === 4
					? "red.100"
					: status === 3
						? "orange.100"
						: status === 2
							? "yellow.100"
							: status == 1
								? "green.100"
								: "gray.100"
				: status === 3
					? "red.100"
					: status === 2
						? "yellow.100"
						: status == 1
							? "green.100"
							: "gray.100";
		return color;
	};

	const fontColorSelector = (indiceNumber: string, maxValue: number) => {
		const status = parseInt(indiceNumber);
		const color =
			maxValue === 4
				? status === 4
					? "red.500"
					: status === 3
						? "orange.500"
						: status === 2
							? "yellow.700"
							: status == 1
								? "green.600"
								: "gray.500"
				: status === 3
					? "red.500"
					: status === 2
						? "yellow.700"
						: status == 1
							? "green.600"
							: "gray.500";
		return color;
	};

	const statusBoxProps: BoxProps = {
		borderRadius: "5px",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		minW: "3.5rem",
		bgColor: !hideColors ? statusColorSelector(indiceNumber, maxIndiceLength(indice)) : "transparent",
	};

	return (
		<Flex>
			{indice !== null && (
				<Box {...statusBoxProps}>
					<Text
						textStyle="body3"
						m=".35rem .75rem"
						color={!hideColors ? fontColorSelector(indiceNumber, maxIndiceLength(indice)) : "gray.500"}
					>
						{firstLetters}
						<Text as="sub">{subIndice}</Text>
						{indiceNumber}
					</Text>
				</Box>
			)}
		</Flex>
	);
};

export default IndiceIndicator;
